import BaseBean from "@/utils/BaseBean";

export interface ICxDataObj {
    utilInst:CxUtil
    refMap:Map<string,any>
    pageListRef:any
    otherParams:any
}

export default class CxUtil extends BaseBean{
    public dataObj:ICxDataObj

    constructor(proxy:any,dataObj:ICxDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    public async initPage():Promise<any>{
        this.dataObj.otherParams.cxData=await this.utils.ToolsProviderApi.clientQueryManualCxByBrandId({brandId:this.dataObj.otherParams.brandId});
    }

}